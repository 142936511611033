<template>
  <div>
    <div class="case-contaion">
      <div
        class="case-flex"
        v-for="(item, index) in caselist"
        :key="index"
        @click="playClick(item.caseId)"
      >
        <div class="case-left">
          <div class="left-photo">
            <div class="bg-img">
              <van-image :src="item.cover" style="width: 100%; height: 100%">
                <template v-slot:error>
                  <i
                    class="u-iconfont uicon-error-circle"
                    style="font-size: 23px"
                  ></i>
                </template>
              </van-image>
            </div>
            <img
              v-if="item.isPlay"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/button.png"
              width="30"
              height="30"
            />
          </div>
        </div>
        <div class="case-right">
          <div class="case-title">
            {{ item.title }}
          </div>
          <div class="case-message">{{ item.remark }}</div>
          <div class="customer" v-if="item.customer">
            <span
              style="
                width: 20px;
                height: 1px;
                background-color: #cccccc;
                display: inline-block;
                margin-right: 10px;
              "
            ></span>
            {{ item.customer }}
          </div>
          <div class="jump">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!caselist.length" class="empty-box">
      <i class="u-iconfont uicon-empty-order"></i>
      <div class="empty-text">数据暂无</div>
    </div>
  </div>
</template>
<script>
import { getProductCaseList } from "@/api/service";
export default {
  name: "CaseShow",
  data() {
    return {
      caselist: [],
      processId: undefined,
    };
  },
  created() {
    this.processId = this.$route.query.productId;
    this.onload();
  },
  methods: {
    onload() {
      let query = {
        productId: this.processId,
        pageIndex: 1,
        pageSize: 10,
      };
      getProductCaseList(query).then((res) => {
        let data = res.data;
        data.forEach((item) => {
          if (item.video) {
            item.isPlay = true;
          }
        });
        this.caselist = data;
      });
    },
    playClick(caseId) {
      enterCaseDetail.postMessage(
        JSON.stringify({
          urlString:
            "http://qa-static.clozworld.com/#/case/details?caseId=" + caseId,
        })
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./iconfont.css";

.case-contaion {
  padding: 0 10px;
}
.empty-box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .empty-text {
    padding: 10px;
    color: #c0c4cc;
    font-size: 13px;
  }
}
.empty-box /deep/.uicon-empty-order {
  font-size: 60px;
  font-weight: normal;
  color: #c0c4cc;
}
.case-flex {
  display: flex;
  background: #fff;
  margin: 10px 0;
  border-radius: 5px;
  padding-right: 12px;
  height: 115px;
}
.case-left {
  margin-right: 20px;
  /*flex: 1;*/
}

.left-photo {
  position: relative;
  width: 178px;
  height: 115px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  img {
    position: relative;
    z-index: 2;
  }
}

.case-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.case-title {
  font-size: 14px;
  font-family: PingFang SC;

  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;

  font-weight: 800;
  color: #333333;
}
.jump {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 6px;
}
.jump /deep/.van-icon {
  font-size: 16px;
  font-weight: bold;
  color: #666666;
}
.case-message {
  /*margin-top: 20px;*/

  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}
.customer {
  display: flex;
  align-items: center;
  color: #666666;
  font-size: 12px;
  justify-content: flex-end;
}
</style>
